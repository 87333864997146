












































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class PolicyPreferenceModal extends Vue {
  @Prop({ default: "md" }) public size!: any;
  @Prop() public data: any;

  get addressDetails() {
    return `${this.data.address}, ${this.data.city}, ${this.data.state}, ${this.data.country}, ${this.data.zipcode}`
  }

  show() {
    this.$bvModal.show("policy-preference-modal");
  }

  hide() {
    this.$bvModal.hide("policy-preference-modal");
  }

}
