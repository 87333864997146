
















































import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class FilterComponent extends Vue {
  @Prop() public label!: any;
  @Prop() public options!: any[];
  selected = [];

  onCancel(): void {
    const ref = this.$refs.dropdown as HTMLFormElement;
    ref.hide(true);
    this.selected = [];
  }

  @Emit("getInputValue")
  public onChangeInput() {
    const ref = this.$refs.dropdown as HTMLFormElement;
    ref.hide(true);
    if (this.selected && this.selected.length > 0) {
      return this.selected;
    }
  }
}
